import React from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { FaUser, FaSignOutAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";

const Header = () => {
  return (
    <header>
      <Navbar bg="primary" variant="winered" expand="lg">
        <Link to="/">
          <Navbar.Brand
            style={{ color: "white", marginLeft: 45 }}
            align="center"
          >
            kungarna.se
          </Navbar.Brand>
        </Link>

        <Col align="right" style={{ marginRight: 45 }}>
          <a href="mailto:tobias@supply24.se">
            {" "}
            <h4 style={{ color: "white" }}>
              {" "}
              <FaRegEnvelope />
            </h4>
          </a>
        </Col>
      </Navbar>
    </header>
  );
};

export default Header;
