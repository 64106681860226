import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Col align="center">
        <h4> Drottningar & Kungar </h4>
        <Button onClick={null} variant="light">
          <Link to="/queenking" style={{ textDecoration: "none" }}>
            Monarkerna
          </Link>{" "}
        </Button>
      </Col>
      <br></br>
      <Row>
        <Col align="center">
          <h6>
            Please bare with us for a short while. The website kungarna.se will
            soon be up and running!
          </h6>
        </Col>
      </Row>
    </>
  );
};

export default Home;
