import React, { useState } from "react";
import queen from "../queens";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import Queening from "../components/Queening";
import Queening1 from "../components/Queening1";
import { IoReturnUpBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const QueenScreen = (queen) => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        {" "}
        <Button variant="primary" size="sm" onClick={() => navigate(-1)}>
          <IoReturnUpBack />{" "}
        </Button>
        <br></br>
        <br></br>
        <Col>
          <h2 style={{ color: "white" }} align="center">
            {" "}
            Drottningar & Kungar{" "}
          </h2>{" "}
        </Col>
      </div>
      <Queening />

      <ScrollToTop />
    </>
  );
};

export default QueenScreen;
