import React from "react";
import { useParams } from "react-router-dom";
import { Card, Row, Col, Button, Image } from "react-bootstrap";
import queens from "../queens";
import { Link } from "react-router-dom";
import { PiCross } from "react-icons/pi";
import konungalength from "../components/images/konungalength.jpg";
import { IoReturnUpBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const QueenIdScreen = () => {
  const { id: queenId } = useParams();

  const navigate = useNavigate();

  const queen = queens.find((p) => p._id === queenId);
  console.log(queen);

  function addDefaultSrc(ev) {
    ev.target.src = konungalength;
  }

  return (
    <div>
      <Button variant="primary" size="sm" onClick={() => navigate(-1)}>
        <IoReturnUpBack />{" "}
      </Button>
      <br></br> <br></br>
      <Card>
        <br></br>
        <Row>
          <Col align="center">
            {" "}
            <h4> {queen.name}</h4>{" "}
          </Col>
        </Row>

        <Row>
          <Col align="center"> In Power: {queen.in_power}</Col>
        </Row>

        <Row>
          <Col align="center">
            <small> Ätt: {queen.rating} </small>
          </Col>
        </Row>

        <Row>
          <Col align="center">
            {" "}
            <Image
              src={queen.image}
              height={140}
              width={140}
              onError={addDefaultSrc}
            ></Image>
          </Col>
        </Row>

        <Row align="center">
          <Col align="center">
            {" "}
            <Button>
              <Row>
                <Col align="center">{queen.general} </Col>
              </Row>{" "}
              <br></br>
              Lifetime: <br></br> {queen.lifetime}(<PiCross />)
            </Button>
          </Col>
        </Row>

        <br></br>
      </Card>
    </div>
  );
};

export default QueenIdScreen;
